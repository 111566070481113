export const headers = [
	"UID",
	"G1",
	"G2",
	"G3",
	"G4",
	"G5",
];

export const json = [
	["FASN",
		-0.270038787972887,
		0.0222318112495894,
		-0.049409089467233,
		-0.112706523417612,
		0.00648557959465411,
	],
	["TFRC",
		-0.242694546980596,
		0.0392697590513962,
		0.0081879080293812,
		-0.106063809797828,
		0.0140915167177547,
	],
	["ACC1",
		-0.22219194689963,
		-0.0334027763235784,
		-0.0305484084102175,
		-0.0613290785250416,
		0.0156450812563754,
	],
	["CYCLINB1",
		-0.210935406711449,
		-0.0116198756025992,
		-0.0713719464656459,
		-0.151889193563368,
		0.100704474296415,
	],
	["GAPDH",
		-0.197559318497615,
		0.154254848807168,
		0.0242922734394197,
		0.0625566505343292,
		-0.00601555114258345,
	],
	["EPPK1",
		-0.193142518303797,
		-0.497593870845726,
		0.202748001321372,
		0.208767261381581,
		-0.127484845188313,
	],
	["IGFBP2",
		-0.13342817948063,
		-0.0553770987187898,
		0.002938666982837,
		-0.107270731659491,
		-0.0140014489957075,
	],
	["ERALPHA",
		-0.121892647769379,
		-0.237416690957924,
		-0.0328726991892776,
		-0.0751528167656938,
		-0.0256483214850956,
	],
	["ACC_pS79",
		-0.111127222385525,
		-0.012549786572272,
		-0.022298276396344,
		0.000253290633234621,
		0.0577077166673153,
	],
	["EIF4G",
		-0.108846233331655,
		-0.000458305746568233,
		-0.0692878091196017,
		0.0535406907578699,
		0.0087800199521089,
	],
	["BRD4",
		-0.107085604328595,
		0.0228144046322047,
		-0.0710820213279517,
		0.0563229825981475,
		-0.00545549360954204,
	],
	["GSK3ALPHABETA",
		-0.0320237583302692,
		0.0157859452517228,
		-0.00494376065904133,
		0.0379366226336298,
		0.00383761378719693,
	],
	["RBM15",
		-0.101332547994315,
		0.0087384871725418,
		-0.0921081462776868,
		0.105849055893847,
		0.0376469176964847,
	],
	["X53BP1",
		-0.0991708327045142,
		-0.00712227425972817,
		-0.15022055548877,
		0.0844476680912712,
		-0.0264425918621031,
	],
	["SMAD1",
		-0.0931241683355003,
		-0.0494110615523978,
		-0.0430071893641997,
		-0.0416386963776834,
		-0.0396353558461015,
	],
	["CKIT",
		0.0841239909994407,
		0.117844287391892,
		-0.115471454094683,
		-0.0026636273602972,
		-0.0482996862837946,
	],
	["HSP70",
		0.0846222408111488,
		0.0108908873960041,
		0.118867619204463,
		-0.0701703279837281,
		-0.166190149294694,
	],
	["LKB1",
		0.102599502406737,
		-0.01341997315838,
		-0.0467486048908168,
		-0.0577419121555575,
		0.028705027808222,
	],
	["BCL2",
		0.121235190327825,
		0.0473684580563868,
		-0.0640946431787703,
		0.0971586694771819,
		0.00910700741511007,
	],
	["ANNEXIN1",
		0.13373781533754,
		9.69988870681349e-05,
		0.23991403522676,
		0.290840181719405,
		-0.0750002191295617,
	],
	["FIBRONECTIN",
		0.156792097721708,
		-0.0256347481735202,
		0.366151012601794,
		0.198768877263577,
		-0.0642389414884863,
	],
	["CLAUDIN7",
		0.202545488677278,
		-0.288075148679275,
		-0.275032786573366,
		-0.0916711314724381,
		0.0604018676345054,
	],
	["TIGAR",
		0.409331123767085,
		-0.043412975629389,
		-0.378096467484143,
		-0.142544816027473,
		0.11554958980105,
	],
	["AKT",
		-0.0309966320187181,
		0.154189476197307,
		-0.108072754734118,
		0.0719672511875265,
		-0.0625657993247515,
	],
	["EPPK1",
		-0.193142518303797,
		-0.497593870845726,
		0.202748001321372,
		0.208767261381581,
		-0.127484845188313,
	],
	["HER2",
		-0.0521620297881377,
		-0.118898130267841,
		-0.112839395538898,
		0.0750694746385853,
		-0.0584263490859668,
	],
	["HER3",
		-0.00446189457330712,
		0.124745522234314,
		0.0201038481894889,
		0.0287136063937543,
		0.0652946679458804,
	],
	["PAXILLIN",
		0.0448303172638954,
		0.100963171517024,
		-0.0146269268842884,
		0.155756377469427,
		-0.0061480175716194,
	],
	["INPP4B",
		-0.0123868667172068,
		-0.1016562295619,
		0.0197980241434606,
		-0.0370551799607708,
		0.0641507504897825,
	],
	["P27",
		0.0235217888598671,
		-0.0173413224084672,
		-0.014843823462312,
		-0.0312104971629547,
		0.0260875424235971,
	],
	["P38_pT180Y182",
		0.0535359692127158,
		-0.0155508868865335,
		-0.0265361028565063,
		0.122395436045752,
		-0.142996079128044,
	],
	["MYH11",
		0.0617801210482105,
		-0.119008248052679,
		0.0804629187010467,
		-0.171447443300022,
		-0.483058298808707,
	],
	["SMAC",
		0.0417184556953478,
		0.0201087428745143,
		0.139053667106771,
		0.0991362871194261,
		0.152580545447961,
	],
	["SRC_pY527",
		0.0147349414368569,
		0.0254815166306754,
		-0.0796860395138016,
		-0.0256148254727322,
		-0.140936500873183,
	],
	["RICTOR",
		-0.0288662730263312,
		0.000458460192347997,
		-0.0513123677526092,
		-0.000528141885329737,
		-0.100193116337988,
	],
	["PDCD4",
		0.0593607178004868,
		-0.0406507384879815,
		-0.15183163431229801,
		0.164022536610686,
		-0.126981139667639,
	],
	["DUSP4",
		0.0401488220115446,
		0.255813074508881,
		0.0777272576098117,
		0.105092143333137,
		0.107997496781026,
	],
	["BRAF",
		-0.0515698266429733,
		0.0224318318120419,
		-0.11072597787389,
		0.103327857127887,
		0.034088369804087,
	],
	["PAI1",
		-0.00175070521056849,
		0.137088593444004,
		0.16644687553586,
		-0.152899041484729,
		-0.0703154977708441,
	],
];